import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import PostList from '../components/Posts/PostList';
import CategoryFilter from '../components/Posts/CategoryFilter';
import { TextHeader } from '../acf/TextHeader/TextHeader.jsx';
import { decodeEntities } from '../utils/htmlParse';

const Category = props => {
  const { data, pageContext, location } = props
  const { allWordpressPost, site, blogPageComponents } = data;
  const { edges: posts, totalCount } = allWordpressPost
  const { title: siteTitle } = site.siteMetadata;
  const { name: categoryName, slug: categorySlug } = pageContext;
  const { childWordPressAcfTextHeader = null } = blogPageComponents;
  const title = `${totalCount} post${totalCount === 1 ? '' : 's'} in the “${categoryName}” category`
  return (
    <Layout location={location}>
      <SEO title={`${decodeEntities(categoryName)} | ${decodeEntities(siteTitle)}`} />
      {childWordPressAcfTextHeader && (
        <TextHeader
          smallHeading={categoryName}
          heading={childWordPressAcfTextHeader.heading}
        />
      )}
      <CategoryFilter pathPrefix={`${site.siteMetadata.blogSlug}`} location={location} />
      <PostList
        posts={posts}
        title={title}
        blogSlug={site.siteMetadata.blogSlug}
        pageContext={pageContext}
        siteMetadata={site.siteMetadata}
        pathPrefix={`/${site.siteMetadata.blogSlug}/category/${categorySlug}/`}
        location={location}
      />
    </Layout>
  )
}
export default Category

export const pageQuery = graphql`
  query CategoryPage($slug: String!, $limit: Int!, $skip: Int!) {
    site {
      siteMetadata {
        title
        blogSlug
      }
    }
    blogPageComponents: wordpressPage(slug: { eq: "news" }) {
      childWordPressAcfTextHeader {
        smallHeading
        heading
        subHeading
      }
    }
    allWordpressPost(
      filter: {
        categories: { elemMatch: { slug: { eq: $slug } } }
        slug: { ne: "gatsby-build-post" }
      }
      sort: { fields: date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          id
          featuredImage: featured_media {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1200, quality: 90) {
                  ... GatsbyImageSharpFluid
                }
              }
            }
          }
          title
          content
          date(formatString: "MMMM DD, YYYY")
          slug
        }
      }
    }
  }
`
