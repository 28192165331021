import React from 'react'
import { Link } from 'gatsby'
import { compileQuery } from '../../utils/helpers'
import '../Pagination.scss'

const ProductsPagination = props => {
  const { pageContext, showCounts = true, pathPrefix, location } = props
  if (!pageContext) {
    return null
  }
  console.log('props', props)
  const {
    previousPagePath = null,
    nextPagePath = null,
    numberOfPages = null,
  } = pageContext
  const pages = [...Array.from(Array(numberOfPages).keys()).map(n => n + 1)]
  console.log('pages:', pages)
  return (
    <div className="pagination-container">
      <div className="wrapper">
        <nav className="pagination" role="navigation">
          <div className="navbar navbar-menu">
            {previousPagePath && (
              <Link to={`/${previousPagePath}/`} className="prev">
                Previous
              </Link>
            )}
            {showCounts &&
              pages.map((key, index) => {
                const pageTo =
                  key === 1 ? `/${pathPrefix}/` : `/${pathPrefix}/page/${key}/`
                const isActive =
                  `${location.pathname}` === pageTo ? 'active' : ''
                return (
                  <div className="page" key={index}>
                    <Link to={pageTo} className={isActive}>
                      {key}
                    </Link>
                  </div>
                )
              })}
            {nextPagePath && (
              <Link to={`/${nextPagePath}/`} className="next">
                Next
              </Link>
            )}
          </div>
        </nav>
      </div>
    </div>
  )
}

export default ProductsPagination
