import React, { Component } from 'react'
import { Link } from 'gatsby'
import Image from '../Image'
import Pagination from './Pagination'
import { decodeEntities, getExcerpt } from '../../utils/htmlParse'
import { getUrlVars } from '../../utils/helpers'
import './PostList.scss'

export default class PostList extends Component {
  render() {
    const {
      posts,
      title = 'Posts',
      blogSlug,
      pathPrefix,
      pageContext,
      location,
    } = this.props
    const filters = getUrlVars()
    return (
      <section className="post-feed">
        <div className="wrapper">
          {posts && (
            <div className="feed-items">
              {posts.map(({ node: post }) => {
                const {
                  title: postTitle,
                  content,
                  excerpt,
                  featuredImage,
                  slug,
                  acf,
                } = post
                return (
                  <Link
                    to={`/${blogSlug}/${slug}/`}
                    className="feed-item"
                    key={post.id}
                  >
                    <div className="feed-item-image">
                      <Image image={featuredImage} />
                    </div>
                    <div className="feed-item-details">
                      {acf && acf.service && (
                        <span className="feed-item-service">
                          {decodeEntities(acf.service.title)}
                        </span>
                      )}
                      {acf && acf.subService && (
                        <span className="feed-item-sub-service">
                          {decodeEntities(acf.subService.title)}
                        </span>
                      )}
                      <h2
                        dangerouslySetInnerHTML={{ __html: postTitle }}
                        className="feed-item-heading"
                      />
                      <p
                        dangerouslySetInnerHTML={{
                          __html: getExcerpt(content, 100),
                        }}
                        className="feed-item-excerpt"
                      />
                    </div>
                  </Link>
                )
              })}
            </div>
          )}
          <Pagination
            pageContext={pageContext}
            pathPrefix={pathPrefix}
            showCounts={true}
            location={location}
          />
        </div>
      </section>
    )
  }
}
