import React from 'react'
import { useStaticQuery, graphql, navigate } from 'gatsby'
import { decodeEntities } from '../../utils/htmlParse'
import './CategoryFilter.scss'

const CategoryFilter = ({ pathPrefix, location }) => {
  const navigateToCategory = event => {
    return navigate(
      `/${pathPrefix}/${event.target.value ? `${event.target.value}/` : ''}`
    )
  }
  // Remove trailing slash
  const activeFilterPathname = location.pathname.replace(/\/$/, '')
  const activeFilter = activeFilterPathname.substr(
    activeFilterPathname.lastIndexOf('/') + 1
  )
  const { allWordpressCategory } = useStaticQuery(graphql`
    query {
      allWordpressCategory(
        filter: { count: { gt: 0 }, slug: { ne: "uncategorized" } }
      ) {
        nodes {
          id
          name
          slug
        }
      }
    }
  `)
  return (
    <div className="posts-category-filter">
      <div className="wrapper">
        <div className="filters-container">
          <span className="filter-label">Category</span>
          <form>
            <div className="form-inner">
              <div>
                <select
                  className="filter-select"
                  onChange={event => navigateToCategory(event)}
                >
                  <option selected value="">
                    All
                  </option>
                  {allWordpressCategory.nodes.map(cat => {
                    return (
                      <option
                        value={cat.slug}
                        key={cat.id}
                        selected={activeFilter === cat.slug}
                      >
                        {decodeEntities(cat.name)}
                      </option>
                    )
                  })}
                </select>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default CategoryFilter
